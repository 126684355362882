const STRAPI_URL = process.env.NEXT_PUBLIC_STRAPI_URL;

const STRAPI_API_URL = `${STRAPI_URL}/api`;

const STRAPI_API_KEY = process.env.STRAPI_API_KEY;

const BACKEND_URL = process.env.BACKEND_URL as string;

const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string;

const QUINO_PROXY_URL = process.env.NEXT_PUBLIC_PROXY_URL as string;

const { hostname } = new URL(QUINO_PROXY_URL);
const QUINO_PROXY_HOSTNAME = hostname;
export {
  STRAPI_API_URL,
  STRAPI_URL,
  STRAPI_API_KEY,
  BACKEND_URL,
  MIXPANEL_TOKEN,
  QUINO_PROXY_URL,
  QUINO_PROXY_HOSTNAME,
};
