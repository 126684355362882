import { useCallback } from "react";
import { mixpanelTrack } from "@/service/utils";
import { useRouter } from "next/navigation";
import { useLocalStorage } from "usehooks-ts";
import { MIXPANEL_DISTINCT_ID } from "@/constants/landing";

export const useForcedMixpanelRedirectTrack = () => {
  const router = useRouter();

  return useCallback(
    (link: string, event?: string, payload?: object) => {
      if (!event) {
        router.push(link);
        return;
      }
      try {
        const worked = mixpanelTrack(event, payload, () => {
          setTimeout(() => router.push(link), 1000);
        });
        if (!worked) router.push(link);
      } catch (e) {
        router.push(link);
      } finally {
        setTimeout(() => router.push(link), 2000);
      }
    },
    [router],
  );
};

export const useMixpanelDistinctId = () => useLocalStorage(MIXPANEL_DISTINCT_ID, "");
