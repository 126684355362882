import { type z } from "zod";
import { type Mixpanel, type OverridedMixpanel, type Response } from "mixpanel-browser";
import { MIXPANEL_TOKEN, QUINO_PROXY_URL } from "@/constants/api";

export async function getRequest<T>(endpoint: string, initData?: RequestInit): Promise<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = await fetch(endpoint, {
    next: initData?.cache === undefined ? { revalidate: 60 } : undefined,
    ...initData,
  });

  if (!response.ok) {
    throw new Error("API call failed with: " + response.statusText);
  }
  return (await response.json()) as Promise<T>;
}

export async function putRequest<T>(endpoint: string, initData?: RequestInit): Promise<T> {
  const response = await fetch(endpoint, { method: "PUT", ...initData });
  return (await response.json()) as Promise<T>;
}

export async function postRequest<T>(endpoint: string, body: object, initData?: RequestInit): Promise<T> {
  const response = await fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    ...initData,
  });
  if (!response.ok) throw new Error("Request failed");
  if (response.status === 202) return { result: "ok" } as unknown as T;
  if (response.headers.get("content-length") === "0") return {} as unknown as T;
  return (await response.json()) as Promise<T>;
}

export async function postRequestForFile<T>(endpoint: string, body: FormData, initData?: RequestInit): Promise<T> {
  const response = await fetch(endpoint, {
    method: "POST",
    body: body,
    ...initData,
  });
  if (!response.ok) throw new Error("Request failed");
  if (response.status === 202) return { result: "ok" } as unknown as T;
  if (response.headers.get("content-length") === "0") return {} as unknown as T;
  return (await response.json()) as Promise<T>;
}

export async function validateZodSchema<T>(promise: Promise<T>, validator: z.ZodSchema<unknown>): Promise<T> {
  const response = await promise;
  if (process.env.NODE_ENV === "development") {
    const parseResult = validator.safeParse(response);
    if (!parseResult.success) {
      console.error(parseResult.error);
    }
  } else validator.parse(response);

  return response;
}

export const mixpanelTrack = (event: string, payload?: object, callback?: (response: Response) => void) => {
  if (Object.hasOwn(window, "mixpanel")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mixpanel = window.mixpanel as Mixpanel;

    mixpanel.track(event, payload, callback);
    return true;
  }
  return false;
};

export const mixpanelInit = () => {
  if (Object.hasOwn(window, "mixpanel")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mixpanel = window.mixpanel as OverridedMixpanel;

    mixpanel.init(MIXPANEL_TOKEN, {
      debug: false,
      api_host: QUINO_PROXY_URL,
      cross_subdomain_cookie: true,
      track_pageview: true,
    });
    return true;
  }
  return false;
};

export const getMixpanelDistinctId = (): string => {
  if (Object.hasOwn(window, "mixpanel")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mixpanel = window.mixpanel as OverridedMixpanel;
    if (typeof mixpanel.get_distinct_id === "function") return mixpanel.get_distinct_id() as string;
    return "";
  }
  return "";
};

export const mixpanelTrackLinks = (bind: string, event: string, payload?: object) => {
  if (Object.hasOwn(window, "mixpanel")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mixpanel = window.mixpanel as Mixpanel;

    mixpanel.track_links(bind, event, payload);
  }
};

export const oneYearFromNowDate = () => {
  // Create a new Date object
  const date = new Date();

  // Add one month to the current date
  date.setFullYear(date.getFullYear() + 1);

  // Output the date one month from now
  return date;
};
