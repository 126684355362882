import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { PlusJakartaSans } from "@/service/fonts";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  cn(
    "focus-visible:ring-ring inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg font-jakarta text-base font-bold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    PlusJakartaSans.className,
  ),
  {
    variants: {
      variant: {
        contained: "bg-primary-container-default text-primary-text-default hover:bg-primary-container-hover",
        "default-white":
          "bg-primary-container-default-on-color text-default-on-color hover:bg-primary-container-hover-on-color",
        "primary-orange": "bg-primitive-orange-300 text-white hover:bg-primitive-orange-300/75",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border-input hover:bg-accent hover:text-accent-foreground border bg-background",
        secondary: "bg-secondary-container-default text-secondary-text-default hover:bg-secondary-container-hover",
        ghost: "text-tertiary-text-default hover:bg-tertiary-container-hover",
        "text-link": "!h-4 !p-0 text-link-active-on-bg hover:text-primary-on-bg",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        lg: "h-14 px-8 text-base",
        md: "h-12 px-5 py-4 text-sm",
        sm: "h-10 px-4 py-3 text-xs",
        xs: "h-7 gap-1 p-1.5 text-xs",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "contained",
      size: "md",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
