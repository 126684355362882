import type { ElementRef, SVGProps } from "react";
import { cn } from "@/lib/utils";

export const ArrowUpRight = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
    className={cn("h-6 w-6 fill-primary", props.className)}
  >
    <path
      className="fill-inherit"
      d="M18 5a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V8.414l-9.95 9.95a1 1 0 0 1-1.414-1.414L15.586 7H10a1 1 0 1 1 0-2h8Z"
    />
  </svg>
);

export const MailSend = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-[25px] w-[25px] fill-primitive-purple-pale-500", props.className)}
  >
    <path
      d="M20.334 4.34094C20.8386 4.34078 21.3246 4.53135 21.6945 4.87444C22.0645 5.21753 22.2911 5.68779 22.329 6.19094L22.334 6.34094V18.3409C22.3341 18.8455 22.1436 19.3315 21.8005 19.7015C21.4574 20.0715 20.9871 20.2981 20.484 20.3359L20.334 20.3409H4.33398C3.82941 20.3411 3.34342 20.1505 2.97344 19.8074C2.60346 19.4644 2.37683 18.9941 2.33898 18.4909L2.33398 18.3409V17.3409H4.33398V18.3409H20.334V7.75494L13.394 14.6949C13.1315 14.9574 12.781 15.1132 12.4103 15.1322C12.0396 15.1513 11.675 15.0321 11.387 14.7979L11.273 14.6949L4.33398 7.75494V8.34094H2.33398V6.34094C2.33382 5.83637 2.52439 5.35038 2.86748 4.98039C3.21057 4.61041 3.68083 4.38379 4.18398 4.34594L4.33398 4.34094H20.334ZM6.33398 13.3409C6.5992 13.3409 6.85355 13.4463 7.04109 13.6338C7.22863 13.8214 7.33398 14.0757 7.33398 14.3409C7.33398 14.6062 7.22863 14.8605 7.04109 15.048C6.85355 15.2356 6.5992 15.3409 6.33398 15.3409H1.33398C1.06877 15.3409 0.814414 15.2356 0.626878 15.048C0.439341 14.8605 0.333984 14.6062 0.333984 14.3409C0.333984 14.0757 0.439341 13.8214 0.626878 13.6338C0.814414 13.4463 1.06877 13.3409 1.33398 13.3409H6.33398ZM18.92 6.34094H5.74798L12.334 12.9269L18.92 6.34094ZM5.33398 10.3409C5.58886 10.3412 5.83402 10.4388 6.01935 10.6138C6.20469 10.7888 6.31622 11.0279 6.33116 11.2823C6.34609 11.5368 6.26331 11.7873 6.09972 11.9828C5.93613 12.1782 5.70408 12.3038 5.45098 12.3339L5.33398 12.3409H2.33398C2.0791 12.3407 1.83395 12.2431 1.64862 12.0681C1.46328 11.8931 1.35175 11.654 1.33681 11.3995C1.32188 11.1451 1.40466 10.8946 1.56825 10.6991C1.73184 10.5037 1.96389 10.378 2.21698 10.3479L2.33398 10.3409H5.33398Z"
      className="fill-inherit"
    />
  </svg>
);
