import React from "react";
export const QUINO_ROOT_DOMAIN = process.env.NEXT_PUBLIC_QUINO_ROOT_DOMAIN as string;
export const QUINO_APP_URL = process.env.NEXT_PUBLIC_QUINO_APP_URL as string;

export const LANDING_SECTION_IDS = {
  FEATURES: "features",
};

export const CONTACT_EMAIL = "hello@quino.ai";
export const LANDING_HEADER_PRICING = [
  {
    title: "Free Plan",
    description: "Read, take notes, organize your learning materials and try Quino's AI features.",
    price: "Free",
  },
  {
    title: "Pro Plan",
    description: "Boost your study sessions with Quino and take advantage of all the AI tools.",
    price: "$7/month",
  },
];

export const LANDING_REDIRECT_ROUTES = {
  HOME: "/",
  LOGIN: `${QUINO_APP_URL}/login`,
  DEMO: `${QUINO_APP_URL}/demo/documents/all/PROJECT_ID_THREE?note-tab=document-summary&document=DOCUMENT_ID_THREE`,
  REGISTER: `${QUINO_APP_URL}/register`,
  PRICING: `/pricing`,
  BLOG: `/blog`,
  FEATURES: `/features`,
  FOR_BUSINESS: "/business",
};

export const QUERY_PARAMS = {
  MIXPANEL_DISTINCT_ID: "mixpanel-distinct-id",
};

export const DOCUMENTATION_PAGE = "https://docs.quino.ai/";

export const SOCIAL_MEDIA_LINKS = {
  FACEBOOK: "https://www.facebook.com/profile.php?id=100089251221328",
  INSTAGRAM: "https://www.instagram.com/quino.ai/",
  TWITTER: "https://twitter.com/Quino_ai",
  TIKTOK: "https://www.tiktok.com/@quino_ai",
  DISCORD: "https://discord.gg/SVJkpqKYSv",
  YOUTUBE: "https://www.youtube.com/@quinoai",
};

interface FeatureDescription {
  id: string;
  title: string;
  description: string | React.ReactNode;
  image: string;
  footerText: string;
}

export const LANDING_FEATURE_LIST: FeatureDescription[] = [
  {
    id: "semantic-search-description",
    title: "Chat with your files",
    description: (
      <>
        Forget inaccurate AI answers. Use Quino to organize your files into projects and <b>ask questions</b> and get
        responses from single or multiple documents using Quino’s source-based chat feature.
      </>
    ),
    image:
      "https://firebasestorage.googleapis.com/v0/b/rukkola-public/o/images%2Flanding-page%2FSemantic_Search.png?alt=media&token=0176564a-1ed0-49cb-8775-ddf6e0024717",
    footerText: "Semantic Search",
  },
  {
    id: "summarize-full-documents",
    title: "Summarize Full Documents",
    description: (
      <>
        Don’t worry about input size anymore. Use Quino to summarize documents of any length. Our AI enables to{" "}
        <b>cost-efficiently and accurately</b> summarize whole books to help you understand information faster.
      </>
    ),
    image:
      "https://firebasestorage.googleapis.com/v0/b/rukkola-public/o/images%2Flanding-page%2FFrame%201759.png?alt=media&token=1179bb92-2840-4b28-9bc9-043c8cb26a74",
    footerText: "Note Taking",
  },
  {
    id: "generate-bullet-points",
    title: "Generate Bullet Points",
    description: (
      <>
        Structure your documents by generating bullet points summarizations to <b>increase your productivity.</b>{" "}
      </>
    ),
    image:
      "https://firebasestorage.googleapis.com/v0/b/rukkola-public/o/images%2Flanding-page%2FTest_your_knowledge.png?alt=media&token=a515dde5-a497-45a8-b61e-3d8117a371f6",
    footerText: "Knowledge Check",
  },
  {
    id: "test-your-knowledge-with-ai",
    title: "Test your Knowledge with AI",
    description: (
      <>
        Generate flashcards and questions to <b>test your knowledge</b> with quizzes and get ready for exams,
        interviews, or tests.
      </>
    ),
    image:
      "https://firebasestorage.googleapis.com/v0/b/rukkola-public/o/images%2Flanding-page%2FOrganize%20your%20documents.png?alt=media&token=520effea-a2ae-4a18-a5a8-fa02d4c0c9f7",
    footerText: "Organise Your Documents",
  },
  {
    id: "experience-speed-and-accuracy",
    title: "Experience Speed and Accuracy",
    description: (
      <>
        Quino’s custom AI models enable instant replies to your requests at a <b>fraction of a cost</b> to other
        services thanks to our alternative vectorization technology.
      </>
    ),
    image:
      "https://firebasestorage.googleapis.com/v0/b/rukkola-public/o/images%2Flanding-page%2FOrganize%20your%20documents.png?alt=media&token=520effea-a2ae-4a18-a5a8-fa02d4c0c9f7",
    footerText: "Organise Your Documents",
  },
];

export const DEMO_VIDEO_URL =
  "https://firebasestorage.googleapis.com/v0/b/rukkola-public/o/Quino_Demo_Video_fin_compressed.mp4?alt=media&token=5f70eb68-5d9b-4c13-a3f9-1de8b228daa9";

export const PAGE_IDS = {
  SUBSCRIPTION_PLANS: "subscription-plans",
  FAQ_ZONE: "faq-zone",
  FEATURES: "feature-zone",
  HERO: "hero-zone",
};

export const USE_CASES = {
  STUDENTS: "students",
  PROFESSIONALS: "professionals",
  RESEARCHERS: "researchers",
};

export const MIXPANEL_DISTINCT_ID = "MIXPANEL-DISTINCT-ID";
